import { useEffect, useState } from 'react';
import { Avatar } from '@mui/material';
import { styled, ThemeProvider } from '@mui/material/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useUserManagementParams } from './useUserManagementParams';
import { PageLoader } from '../../PageLoader';
import { theme } from '../../theme';
import { KwTableHead } from '../../kw-ui-components/KwTableHead';
import { KwTableRow } from '../../kw-ui-components/KwTableRow';
import { KwTable } from '../../kw-ui-components/KwTable';
import { KwTableCell } from '../../kw-ui-components/KwTableCell';
import { KwTableBody } from '../../kw-ui-components/KwTableBody';
import { KwButton } from '../../kw-ui-components/KwButton/KwButton';
import { getComparator } from '../../utils/getComparator';
import { KwColumnSortHeader } from '../../kw-ui-components/KwColumnSortHeader';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { useOrg } from '../Organization/org-provider';
import { useUsersList } from './Integrations';
import { DeleteUserModal } from './DeleteUserModal';
import { InviteUserModal } from './InviteUserModal';

export const UserManagement = () => {
  const { orgData } = useOrg();
  const orgName = orgData?.name || '';

  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const { data: responseData, error: responseError, isLoading, mutate } = useUsersList(accessToken);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  const [users, setUsers] = useState({ users: [], total: 0 });
  // Display a progress indicator when a user is being deleted
  const [progressDisplayed, setProgressDisplayed] = useState(false);

  useEffect(() => {
    if (responseData) {
      setUsers(responseData);
    }
  }, [responseData]);

  const {
    nameOrder,
    createdDateOrder,
    lastLoginOrder,
    toggleNameOrder,
    toggleCreatedDateOrder,
    toggleLastLoginOrder,
    orderBy,
    setOrderBy,
  } = useUserManagementParams();

  const tableHeaders = [
    {
      title: 'Name',
      onClick: toggleNameOrder,
      sortValue: nameOrder,
    },
    {
      title: 'Created Date',
      onClick: toggleCreatedDateOrder,
      sortValue: createdDateOrder,
    },
    {
      title: 'Last Login',
      onClick: toggleLastLoginOrder,
      sortValue: lastLoginOrder,
    },
    {
      title: 'Actions',
      onClick: () => {},
      sortValue: null,
    },
  ];

  // column sorting UI filters
  const order = tableHeaders.map(({ sortValue }) => sortValue).find(o => o);

  useEffect(() => {
    if (nameOrder) {
      setOrderBy('name');
    }

    if (createdDateOrder) {
      setOrderBy('created_at');
    }

    if (lastLoginOrder) {
      setOrderBy('last_login');
    }

    if (!order) {
      setOrderBy(null);
    }
  }, [order, orderBy, setOrderBy, nameOrder, createdDateOrder, lastLoginOrder]);

  const [modalOptions, setModalOptions] = useState({ isOpen: false, user: {} });

  const handleDeleteUser = user => {
    setModalOptions({ isOpen: true, user });
  };

  const handleInviteUser = () => {
    setIsInviteModalOpen(true);
  };

  const formatDate = (timestamp: string): string => {
    if (!timestamp) return '';

    const date = new Date(timestamp);
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    const year = date.getUTCFullYear().toString().slice(-2);
    return `${month}/${day}/${year}`;
  };

  const formatDateTime = (timestamp: string): string => {
    if (!timestamp) return '';

    const date = new Date(timestamp);
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    const year = date.getUTCFullYear().toString().slice(-2);
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours %= 12;
    hours = hours || 12; // the hour '0' should be '12'
    const strTime = `${hours}:${minutes} ${ampm}`;
    return `${month}/${day}/${year} ${strTime}`;
  };

  return (
    <>
      {isLoading && <PageLoader />}
      <ThemeProvider theme={theme}>
        <div style={{ float: 'right', marginBottom: '20px' }}>
          <StyledSummary>
            {orgName} | {users.total} {users.total === 1 ? 'user' : 'users'}
          </StyledSummary>
          <StyledInviteButton size="medium" disabled={false} onClick={event => handleInviteUser()}>
            Invite
          </StyledInviteButton>
        </div>
        <StyledTableWrapper>
          <KwTable aria-label="Users-Table">
            <KwTableHead>
              <KwTableRow>
                {tableHeaders.map(({ title, onClick, sortValue }) => (
                  <KwColumnSortHeader
                    key={title}
                    title={title}
                    onClick={() => {
                      onClick();
                    }}
                    sortValue={sortValue}
                  />
                ))}
              </KwTableRow>
            </KwTableHead>
            <KwTableBody>
              {[...users.users].sort(getComparator(order, orderBy)).map(user => {
                const pictureUrl = user?.picture;

                return (
                  <KwTableRow key={user.user_id} aria-label={`${user.name} row`}>
                    <StyledKwTableCell>
                      <div style={{ display: 'grid' }}>
                        <StyledAvatar alt={`${user.name} app icon`} src={pictureUrl} />

                        <StyledNameTag>{user.name}</StyledNameTag>
                        <StyledEmailTag>{user.email}</StyledEmailTag>
                      </div>
                    </StyledKwTableCell>
                    <KwTableCell>{formatDate(user.created_at)}</KwTableCell>
                    <KwTableCell>{formatDateTime(user.last_login)}</KwTableCell>
                    <KwTableCell>
                      <KwButton size="medium" disabled={false} onClick={() => handleDeleteUser(user)}>
                        <DeleteOutlineIcon />
                      </KwButton>
                    </KwTableCell>
                  </KwTableRow>
                );
              })}
            </KwTableBody>
          </KwTable>
        </StyledTableWrapper>
        <DeleteUserModal
          isOpen={modalOptions.isOpen}
          onCancel={() => setModalOptions({ isOpen: false, user: {} })}
          onDelete={async () => {
            setModalOptions({ isOpen: false, user: {} });
            setProgressDisplayed(true);

            // Refresh the list of users after deletion. Auth0 takes a seconds to update the list of users.
            // Hence I'm using a timeout to refresh the list of users after 2 seconds.
            setTimeout(async () => {
              mutate();
              setProgressDisplayed(false);
            }, 2000);
          }}
          user={modalOptions.user}
        />
        <InviteUserModal
          isOpen={isInviteModalOpen}
          onClose={() => setIsInviteModalOpen(false)}
          onInviteSent={() => {
            mutate();
          }}
        />
        <StyledBackDrop open={progressDisplayed} onClick={() => setProgressDisplayed(false)}>
          <CircularProgress color="inherit" />
        </StyledBackDrop>
      </ThemeProvider>
    </>
  );
};

const StyledBackDrop = styled(Backdrop)(({ theme: muiTheme }) => ({
  color: '#fff',
  zIndex: muiTheme.zIndex.drawer + 1,
}));

const StyledTableWrapper = styled('div')`
  width: 100%;
`;

const StyledKwTableCell = styled(KwTableCell)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledAvatar = styled(Avatar)`
  margin-right: 10px;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 3;
`;

const StyledNameTag = styled('div')`
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
`;
const StyledEmailTag = styled('div')`
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 2;
  color: #757575;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
`;

const StyledInviteButton = styled(KwButton)`
  background-color: #487f87;
  font-size: 13px;
  font-weight: 700;
  line-height: 30px;
  color: white;
`;

const StyledSummary = styled('span')`
  font-size: 18px;
  line-height: 25px;
  color: #757575;
  font-weight: 400;
  margin-right: 20px;
`;
