import { useState } from 'react';
import { Dialog, DialogContent, styled, Typography, InputBase, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import { KwButton } from '../../kw-ui-components/KwButton/KwButton';
import { sendHttpRequest } from '../../utils/network.service';
import { KwFormErrorMessage } from '../../kw-ui-components/KwFormErrorMessage';

interface InviteUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  onInviteSent?: () => void;
}

export const InviteUserModal = ({ isOpen, onClose, onInviteSent }: InviteUserModalProps) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const [formErrors, setFormErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
  });

  const handleClose = () => {
    setFormErrors({
      firstName: false,
      lastName: false,
      email: false,
    });
    onClose();
  };

  const handleSendInvite = async () => {
    const errors = {
      firstName: !firstName.trim(),
      lastName: !lastName.trim(),
      email: !email.trim(),
    };

    setFormErrors(errors);

    try {
      const queryParams = new URLSearchParams({ email, firstName, lastName });
      const path = `${process.env.ORGANIZATION_SERVICE_URL}/organizations/user?${queryParams.toString()}`;

      await sendHttpRequest({
        path,
        method: 'POST',
        successMessage: 'Successfully invited user',
        errorMessage: 'Error inviting user',
        enqueueSnackbar,
      });

      // Reset form
      setFirstName('');
      setLastName('');
      setEmail('');

      if (onInviteSent) onInviteSent();
      onClose();
    } catch (err) {
      console.error('Error sending invite:', err);
    }
  };

  return (
    <StyledDialog open={isOpen} onClose={handleClose} aria-labelledby="invite-user-dialog-title" scroll="body">
      <StyledDialogContent>
        <Typography variant="h3Medium" id="invite-user-modal-title">
          Invite users
          <StyledCloseIcon onClick={handleClose} />
        </Typography>
        <StyledDivider />

        <StyledRow>
          <StyledFormControl>
            <Typography variant="h5Medium">First Name</Typography>
            <StyledInputBase
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              placeholder="First Name"
              error={Boolean(formErrors.firstName)}
            />
            <KwFormErrorMessage error={Boolean(formErrors.firstName)} reason="First name is required." />
          </StyledFormControl>

          <StyledFormControl>
            <Typography variant="h5Medium">Last Name</Typography>
            <StyledInputBase
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              placeholder="Last Name"
              error={Boolean(formErrors.lastName)}
            />
            <KwFormErrorMessage error={Boolean(formErrors.firstName)} reason="Last name is required." />
          </StyledFormControl>
        </StyledRow>
        <Typography variant="h5Medium">Email</Typography>
        <StyledRow>
          <EmailFieldContainer>
            <StyledInputBase value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" error={Boolean(formErrors.email)} />
          </EmailFieldContainer>
          <StyledInviteButton size="medium" onClick={handleSendInvite}>
            Send invite
          </StyledInviteButton>
        </StyledRow>
        <KwFormErrorMessage error={Boolean(formErrors.email)} reason="Email is required." />
      </StyledDialogContent>
    </StyledDialog>
  );
};

const StyledInputBase = styled(InputBase)`
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  display: flex;
  height: 38px;
  margin-top: 8px;
  padding: 12px;
  input {
    font-size: 13px;
    opacity: 0.5;
  }
  &.Mui-disabled {
    background-color: ${props => props.theme.palette.greyOverride[200]};
  }
  &.Mui-focused {
    border: 1px solid ${props => props.theme.palette.accent.primary};
  }
  &.Mui-focused.email-input {
    border: none;
  }
  ${props =>
    props.error &&
    `
      border: 2px solid #f44336;
    `}
`;

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paperScrollBody {
    width: 772px;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 16px;
  background-color: #fafafa;
  & label {
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 4px;
  }
  & label:focus-within {
    border: 1px solid ${props => props.theme.palette.accent.primary};
  }
`;

const StyledDivider = styled(Divider)`
  margin: 8px 0;
`;

const StyledInviteButton = styled(KwButton)`
  background-color: #487f87;
  font-size: 13px;
  font-weight: 700;
  line-height: 30px;
  color: white;
  align-self: flex-end;
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

const EmailFieldContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: flex-end;
`;

const StyledRow = styled('div')`
  display: flex;
  gap: 20px;
  margin-bottom: 16px;
`;

const StyledFormControl = styled('div')`
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  flex: 1; /* each child (firstName/lastName) takes half the row */
`;
