import { useState } from 'react';
import { useCycleState } from '../../utils/useCycleState';

export const useUserManagementParams = () => {
  const [nameOrder, toggleNameOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [createdDateOrder, toggleCreatedDateOrder] = useCycleState([null, 'ASC', 'DESC']);
  const [lastLoginOrder, toggleLastLoginOrder] = useCycleState([null, 'ASC', 'DESC']);

  const toggles = [toggleNameOrder, toggleCreatedDateOrder];

  const [orderBy, setOrderBy] = useState(null);

  const toggleExclude = triggeredToggle => () => {
    triggeredToggle();
    toggles.filter(toggle => toggle !== triggeredToggle).forEach(toggle => toggle(0));
  };

  const resetToggles = () => {
    toggles.forEach(toggle => toggle(0));
  };

  return {
    nameOrder,
    toggleNameOrder: toggleExclude(toggleNameOrder),
    createdDateOrder,
    toggleCreatedDateOrder: toggleExclude(toggleCreatedDateOrder),
    lastLoginOrder,
    toggleLastLoginOrder: toggleExclude(toggleLastLoginOrder),
    orderBy,
    setOrderBy,
    resetToggles,
  };
};
