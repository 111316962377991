import { styled } from '@mui/material/styles';
import { Divider, List, ListItem, ListItemButton, ListItemText, Popover, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useFetchService } from '../../utils/fetchService';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { useStickyResult } from '../../utils/useStickyResult';
import { verifyAuthPermission } from '../../auth/VerifyAuthPermission';
import { AuthPermissions } from '../../auth/AuthPermissions';

interface IProps {
  anchorEl: HTMLDivElement;
  handleClose: () => void;
}

export const useOrganization = (accessToken: string, shouldFetch = true) => {
  const { data: organizationData, error: organizationError, mutate } = useFetchService(`/organizations/`, accessToken, shouldFetch);
  const orgData = useStickyResult(organizationData);
  const isLoading = !orgData && !organizationError;
  return { orgData, organizationError, isLoading, mutate };
};

export default function SettingsDropDownMenu({ anchorEl, handleClose }: IProps) {
  const settingsOpen = Boolean(anchorEl);
  const settingsId = settingsOpen ? 'simple-popover' : undefined;
  const { isAuthenticated, isLoading, user, logout } = useAuth0();
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const { orgData, organizationError } = useOrganization(accessToken);
  const canEditSettings: boolean = verifyAuthPermission(AuthPermissions.MENU_SETTINGS);

  return (
    <Popover
      id={settingsId}
      anchorEl={anchorEl}
      open={settingsOpen}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      aria-label="settings drop down menu"
    >
      <nav>
        <StyledList>
          {isAuthenticated && !isLoading && (
            <>
              <StyledListItem>
                <Typography variant="h3Bold">{user.name}</Typography>
              </StyledListItem>
              <StyledListItem>
                <Typography variant="label12Medium">{user.email}</Typography>
              </StyledListItem>
              {orgData && !organizationError && (
                <StyledListItem>
                  <Typography variant="label12Medium">{orgData?.name ?? ''}</Typography>
                </StyledListItem>
              )}
            </>
          )}
          <StyledDivider role="presentation" />
          {canEditSettings && (
            <StyledListItem disablePadding>
              <ListItemButton component="a" href="/#/settings" onClick={handleClose}>
                <ListItemText primary="Settings" />
              </ListItemButton>
            </StyledListItem>
          )}
          <StyledListItem disablePadding>
            <ListItemButton component="a">
              <ListItemText
                primary="Logout"
                onClick={() => {
                  window.localStorage.setItem('accessToken', '');
                  logout({ returnTo: window.location.origin });
                }}
              />
            </ListItemButton>
          </StyledListItem>
        </StyledList>
      </nav>
    </Popover>
  );
}

const StyledList = styled(List)`
  padding: 8px 0 0 0;
`;

const StyledListItem = styled(ListItem)`
  &:nth-of-type(2),
  &:nth-of-type(3) {
    padding-top: 0;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 10px 16px;
`;
