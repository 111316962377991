import { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { useFetchService } from '../../utils/fetchService';
import { PageLoader } from '../../PageLoader';
import { AuthPermissions } from '../../auth/AuthPermissions';
import { verifyAuthPermission } from '../../auth/VerifyAuthPermission';
import { OktaIntegrationCard } from './OktaIntegrationCard';
// eslint-disable-next-line import/extensions
import { SettingsMode } from './enum/SettingsMode.enum';
import { IntuneIntegrationCard } from './IntuneIntegrationCard';
import { Events, QuokkaEvent, useSubscribeToEvent } from '../../utils/pubSub';

export const useIdentityProviders = (accessToken: string) => {
  const { data: idpApiCredentials, error: idpError, mutate } = useFetchService('/organizations/identity-providers', accessToken);
  const isLoading = !idpApiCredentials && !idpError;
  return { idpApiCredentials, idpError, isLoading, mutate };
};

export const useMdmIntegrations = (accessToken: string) => {
  const { data: intuneSettings, error: intuneError, mutate } = useFetchService('/organizations/mdm-integrations/1', accessToken);
  const isLoadingIntuneSettings = !intuneSettings && !intuneError;
  return { intuneSettings, intuneError, isLoadingIntuneSettings, mutate };
};

export const useUsersList = (accessToken: string) => {
  const { data, error, mutate } = useFetchService('/organizations/users/list', accessToken);
  const isLoading = !data && !error;

  return { data, error, isLoading, mutate };
};

export function Integrations() {
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const [mode, setMode] = useState({ okta: SettingsMode.READ, intune: SettingsMode.READ });
  const { idpApiCredentials = {}, idpError, isLoading, mutate: mutateOkta } = useIdentityProviders(accessToken);
  const { intuneSettings = [], intuneError, isLoadingIntuneSettings, mutate: mutateIntune } = useMdmIntegrations(accessToken);

  // subscribe to when INTUNE sync is done successfully so we can cause a re-render displaying the latest info
  useSubscribeToEvent(Events.INTUNE_SYNC_SUCCESSFUL);
  useEffect(() => {
    const callback = eventData => {
      // calling this function invalidates the cache and causes a re-render
      mutateIntune();
    };
    QuokkaEvent.on(Events.INTUNE_SYNC_SUCCESSFUL, callback);
  }, []);

  const canUpdateOrg = verifyAuthPermission(AuthPermissions.UPDATE_ORG);

  if (idpError || intuneError) {
    return <div>failed to load</div>;
  }

  return (
    <>
      {isLoading || isLoadingIntuneSettings ? (
        <PageLoader />
      ) : (
        <StyledCardContainer>
          <OktaIntegrationCard
            signInUrlPlaceholder={idpApiCredentials[0]?.signInUrl ?? ''}
            managementHintPlaceholder={{
              ios: idpApiCredentials[0]?.iosManagementHint ?? '',
              android: idpApiCredentials[0]?.androidManagementHint ?? '',
            }}
            isAdmin={canUpdateOrg}
            mode={mode}
            setMode={setMode}
            mutate={mutateOkta}
          />
          <IntuneIntegrationCard
            baseUrlPlaceholder={intuneSettings?.find(setting => setting.key === 'base_url')?.value ?? 'https://graph.microsoft.com'}
            clientIdPlaceholder={intuneSettings?.find(setting => setting.key === 'client_id')?.value ?? ''}
            clientSecretPlaceholder={intuneSettings?.find(setting => setting.key === 'client_secret')?.value ?? ''}
            tenantIdPlaceholder={intuneSettings?.find(setting => setting.key === 'tenant_id')?.value ?? ''}
            lastSync={intuneSettings?.find(setting => setting.key === 'last_sync')?.value ?? ''}
            isAdmin={canUpdateOrg}
            mode={mode}
            setMode={setMode}
            mutate={mutateIntune}
          />
        </StyledCardContainer>
      )}
    </>
  );
}

const StyledCardContainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;
