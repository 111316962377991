import { styled } from '@mui/material/styles';
import { ArrowBack, ToggleOff as ToggleOffIcon, ToggleOn as ToggleOnIcon } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar, IconButton, Link, TextField, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { KwContainer } from '../../kw-ui-components/KwContainer';
import { KwButton } from '../../kw-ui-components/KwButton/KwButton';
// eslint-disable-next-line import/extensions
import { AppManagementProfileMode, AppManagementStatus } from './enum/AppManagementKeyMaps.enum';
import { ManagedAppsInfoContext } from './AppManagementInfoContext';
import { sendHttpRequest } from '../../utils/network.service';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { useFetchService } from '../../utils/fetchService';
import { ManagedAppsProfileDescriptionPopover } from './AppManagementPopovers';
import { useRecentlyViewedApps } from './useRecentlyViewedApps';
import { formatPlatform } from '../../utils/formatPlatform';

export const AppManagementProfilePage = ({
  mode,
  status,
  setTab,
}: {
  mode: AppManagementProfileMode;
  status: AppManagementStatus;
  setTab: (tab: number) => void;
}) => {
  const addMode = mode === AppManagementProfileMode.ADD;
  const editMode = mode === AppManagementProfileMode.EDIT;

  const { app, setApp, platform, setPlatform, setSearchHistory, setAppManagementStatus } = useContext(ManagedAppsInfoContext);
  const [accessToken = ''] = useLocalStorage('accessToken', '');
  const { recentlyViewedApps, updateRecentlyViewedApps } = useRecentlyViewedApps();

  const [published, setPublished] = useState(editMode ? app.published : false);
  const [note, setNote] = useState(editMode ? app.note : '');
  const [anchorEl, setAnchorEl] = useState(null);

  const { managedAppId } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [isInManagedList, setIsInManagedList] = useState(false);
  const [isInDeniedList, setIsInDeniedList] = useState(false);
  const [isInTrustedList, setIsInTrustedList] = useState(false);

  const { data: managedAppsResponse } = useFetchService(`/pdm/managed-apps`, accessToken);
  const { data: deniedAppsResponse } = useFetchService(`/list/denied-apps`, accessToken);
  const { data: trustedAppsResponse } = useFetchService(`/list/trusted-apps`, accessToken);

  useEffect(() => {
    if (editMode) {
      if (!managedAppsResponse) return;
      // if app state variable gets reset (e.g. user reloads)
      if (!Object.keys(app).length) {
        const data = managedAppsResponse.find(res => res.managedAppId === managedAppId);
        setApp(data);
        setPublished(data.published);
        setPlatform(data.platform);
        setNote(data.note);
      }
    }
    if (addMode) {
      // if app state variable gets reset (e.g. user reloads)
      if (!Object.keys(app).length) {
        navigate('/app-management');
      }
    }
  }, [addMode, app, editMode, managedAppId, managedAppsResponse, mode, navigate, setApp, setPlatform]);

  const addManagedApp = async () => {
    const { name, packageName } = app;

    const body = addMode
      ? {
          packageName,
          published,
          note,
          platform,
        }
      : {
          id: managedAppId,
          packageName,
          published,
          note,
          platform,
        };

    const endpoint = addMode ? 'add' : managedAppId;
    const successMessage = addMode ? `Successfully added ${name}` : `Successfully edited ${name}`;
    const errorMessage = addMode ? `Error adding ${name}` : `Error editing ${name}`;

    await sendHttpRequest({
      path: `${process.env.PDM_SERVICE_URL}/pdm/managed-apps/${endpoint}`,
      method: addMode ? 'POST' : 'PUT',
      body,
      successMessage,
      errorMessage,
      enqueueSnackbar,
    });

    if (addMode) {
      updateRecentlyViewedApps([...recentlyViewedApps.filter(recentApp => recentApp.name !== name)]);
      setSearchHistory('');
    }

    if (published) {
      await addPublishedAppToTrustedList(app);
    } else {
      await deleteUnpublishedAppFromTrustedList(app);
    }

    navigate('/app-management');
  };

  async function addPublishedAppToTrustedList(app) {
    const { name, packageName, vendorName } = app;

    const successMessage = `Successfully added ${name} to trusted apps`;
    const errorMessage = `Error adding ${name} to trusted apps`;

    const body = {
      bundleId: packageName,
      os: platform,
      name,
      vendorName,
    };

    await sendHttpRequest({
      path: `${process.env.LIST_SERVICE_URL}/list/trusted-apps`,
      method: 'POST',
      body,
      successMessage,
      errorMessage,
      enqueueSnackbar,
    });
  }

  async function deleteUnpublishedAppFromTrustedList(app) {
    const { name, packageName } = app;

    const successMessage = `Successfully deleted ${name}`;
    const errorMessage = `Error deleting ${name}`;

    await sendHttpRequest({
      path: `${process.env.LIST_SERVICE_URL}/list/trusted-apps/${packageName}/${platform}`,
      method: 'DELETE',
      successMessage,
      errorMessage,
      enqueueSnackbar,
    });
  }

  const addDeniedApp = async () => {
    const { name, packageName, vendorName } = app;

    const body = {
      bundleId: packageName,
      os: platform,
      name,
      vendorName,
    };

    const successMessage = addMode ? `Successfully added ${name}` : `Successfully edited ${name}`;
    const errorMessage = addMode ? `Error adding ${name}` : `Error editing ${name}`;

    await sendHttpRequest({
      path: `${process.env.LIST_SERVICE_URL}/list/denied-apps`,
      method: 'POST',
      body,
      successMessage,
      errorMessage,
      enqueueSnackbar,
    });

    updateRecentlyViewedApps([...recentlyViewedApps.filter(recentApp => recentApp.name !== name)]);
    setSearchHistory('');
    navigate('/app-management');
  };

  const addTrustedApp = async () => {
    const { name, packageName, vendorName } = app;

    const body = {
      bundleId: packageName,
      os: platform,
      name,
      vendorName,
    };

    const successMessage = addMode ? `Successfully added ${name}` : `Successfully edited ${name}`;
    const errorMessage = addMode ? `Error adding ${name}` : `Error editing ${name}`;

    await sendHttpRequest({
      path: `${process.env.LIST_SERVICE_URL}/list/trusted-apps`,
      method: 'POST',
      body,
      successMessage,
      errorMessage,
      enqueueSnackbar,
    });

    updateRecentlyViewedApps([...recentlyViewedApps.filter(recentApp => recentApp.name !== name)]);
    setSearchHistory('');
    navigate('/app-management');
  };

  const handleAppDescriptionPopoverClick = (event: { currentTarget: HTMLAnchorElement } = null) => {
    if (!event) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  useEffect(() => {
    if (managedAppsResponse) {
      const matchingApp = managedAppsResponse.find(data => data.packageName === app.packageName && data.platform === platform);
      if (matchingApp) {
        setIsInManagedList(true);
      }
    }

    if (deniedAppsResponse && deniedAppsResponse.deniedApps) {
      const matchingApp = deniedAppsResponse.deniedApps.find(data => data.bundleId === app.packageName && data.os === platform);
      if (matchingApp) {
        setIsInDeniedList(true);
      }
    }

    if (trustedAppsResponse && trustedAppsResponse.trustedApps) {
      const matchingApp = trustedAppsResponse.trustedApps.find(data => data.bundleId === app.packageName && data.os === platform);
      if (matchingApp) {
        setIsInTrustedList(true);
      }
    }
  }, [app.packageName, platform, managedAppsResponse, deniedAppsResponse]);

  const getAppNotification = useMemo(() => {
    if (isInManagedList && addMode) {
      return (
        <StyledAppNotificationContainer>
          <StyledInfoIcon />
          <Typography>
            Remove this app from the Managed Apps list before adding it to a different list. View Managed Apps{' '}
            <StyledTextLink
              onClick={() => {
                setTab(0);
                setAppManagementStatus(AppManagementStatus.MANAGED);
                navigate('/app-management');
              }}
            >
              here
            </StyledTextLink>
            .
          </Typography>
        </StyledAppNotificationContainer>
      );
    }

    if (isInDeniedList && addMode) {
      return (
        <StyledAppNotificationContainer>
          <StyledInfoIcon />
          <Typography>
            Remove this app from the Denied Apps list before adding it to a different list. View Denied Apps{' '}
            <StyledTextLink
              onClick={() => {
                setTab(2);
                setAppManagementStatus(AppManagementStatus.DENIED);
                navigate('/app-management');
              }}
            >
              here
            </StyledTextLink>
            .
          </Typography>
        </StyledAppNotificationContainer>
      );
    }

    if (isInTrustedList && addMode) {
      return (
        <StyledAppNotificationContainer>
          <StyledInfoIcon />
          <Typography>
            Remove this app from the Trusted Apps list before adding it to a different list. View Trusted Apps{' '}
            <StyledTextLink
              onClick={() => {
                setTab(1);
                setAppManagementStatus(AppManagementStatus.TRUSTED);
                navigate('/app-management');
              }}
            >
              here
            </StyledTextLink>
            .
          </Typography>
        </StyledAppNotificationContainer>
      );
    }

    return null;
  }, [isInManagedList, isInTrustedList, isInDeniedList, navigate]);

  return (
    <StyledContainer>
      <StyledSplitPanel>
        <StyledBackSection>
          {addMode ? (
            <KwButton variant="light" startIcon={<ArrowBack />} onClick={() => navigate('/app-management/search')}>
              Back to Search
            </KwButton>
          ) : (
            <KwButton
              variant="light"
              startIcon={<ArrowBack />}
              onClick={() => {
                setTab(0);
                setAppManagementStatus(AppManagementStatus.MANAGED);
                navigate('/app-management');
              }}
            >
              Back to Managed Apps
            </KwButton>
          )}
        </StyledBackSection>
        <StyledDetailsSection>
          <StyledAppInfoItem>
            <StyledAppInfoLabel>
              <Typography variant="h4Medium">App Details</Typography>
            </StyledAppInfoLabel>
            <StyledAppInfoValue>
              <div>{getAppNotification}</div>
            </StyledAppInfoValue>
          </StyledAppInfoItem>
        </StyledDetailsSection>

        <StyledAppLogoSection>
          <StyledAvatar src={app.iconUrl} />
        </StyledAppLogoSection>

        <StyledAppInfoSection>
          <StyledAppInfoItem>
            <StyledAppInfoLabel>
              <StyledTypography>Name</StyledTypography>
            </StyledAppInfoLabel>
            <StyledAppInfoValue>
              <Typography>{app.name}</Typography>
            </StyledAppInfoValue>
          </StyledAppInfoItem>

          {status === AppManagementStatus.MANAGED && (
            <StyledAppInfoItem>
              <StyledAppInfoLabel>
                <StyledTypography>Note</StyledTypography>
              </StyledAppInfoLabel>
              <StyledAppInfoValue>
                <StyledTextField
                  multiline
                  fullWidth
                  defaultValue={app.note}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setNote(event.target.value);
                  }}
                />
              </StyledAppInfoValue>
            </StyledAppInfoItem>
          )}

          <StyledAppInfoItem>
            <StyledAppInfoLabel>
              <StyledTypography>Description</StyledTypography>
            </StyledAppInfoLabel>
            <StyledAppInfoValue>
              {app.description?.length > 275 ? (
                <StyledDescription>
                  {app.description.slice(0, 275)}...
                  <StyledLink onClick={event => handleAppDescriptionPopoverClick(event)}>more</StyledLink>
                </StyledDescription>
              ) : (
                <>
                  <StyledDescription>app.description</StyledDescription>
                </>
              )}
            </StyledAppInfoValue>
          </StyledAppInfoItem>
          <ManagedAppsProfileDescriptionPopover
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            handleClose={() => handleAppDescriptionPopoverClick()}
            description={app.description}
          />
          {(editMode || status === AppManagementStatus.DENIED) && (
            <StyledAppInfoItem>
              <StyledAppInfoLabel>
                <StyledTypography>Current Version</StyledTypography>
              </StyledAppInfoLabel>
              <StyledAppInfoValue>
                <Typography>{app.version}</Typography>
              </StyledAppInfoValue>
            </StyledAppInfoItem>
          )}

          <StyledAppInfoItem>
            <StyledAppInfoLabel>
              <StyledTypography>App ID</StyledTypography>
            </StyledAppInfoLabel>
            <StyledAppInfoValue>
              <Typography>{app.packageName}</Typography>
            </StyledAppInfoValue>
          </StyledAppInfoItem>

          <StyledAppInfoItem>
            <StyledAppInfoLabel>
              <StyledTypography>Genres</StyledTypography>
            </StyledAppInfoLabel>
            <StyledAppInfoValue>
              <Typography>{app.categories?.join(', ')}</Typography>
            </StyledAppInfoValue>
          </StyledAppInfoItem>

          <StyledAppInfoItem>
            <StyledAppInfoLabel>
              <StyledTypography>Vendor</StyledTypography>
            </StyledAppInfoLabel>
            <StyledAppInfoValue>
              <Typography>{app.vendorName}</Typography>
            </StyledAppInfoValue>
          </StyledAppInfoItem>

          <StyledAppInfoItem>
            <StyledAppInfoLabel>
              <StyledTypography>Minimum Operating System</StyledTypography>
            </StyledAppInfoLabel>
            <StyledAppInfoValue>
              <Typography>
                {formatPlatform(platform)} {app.minimumOsVersion}
              </Typography>
            </StyledAppInfoValue>
          </StyledAppInfoItem>

          {addMode && status === AppManagementStatus.MANAGED && (
            <StyledAppInfoItem>
              <StyledAppInfoLabel>
                <StyledTypography>Publish this app now</StyledTypography>
              </StyledAppInfoLabel>
              <StyledAppInfoValue>
                <StyledIconButton onClick={() => setPublished(!published)} edge="start">
                  {!published ? (
                    <>
                      <ToggleOffIcon fontSize="large" />
                      <Typography>No</Typography>
                    </>
                  ) : (
                    <>
                      <ToggleOnIcon fontSize="large" />
                      <Typography>Yes</Typography>
                    </>
                  )}
                </StyledIconButton>
              </StyledAppInfoValue>
            </StyledAppInfoItem>
          )}
        </StyledAppInfoSection>
      </StyledSplitPanel>
      <StyledButtonsContainer>
        {addMode ? (
          <KwButton
            onClick={() => {
              navigate('/app-management/search');
            }}
          >
            Cancel
          </KwButton>
        ) : (
          <KwButton
            onClick={() => {
              navigate('/app-management');
            }}
          >
            Cancel
          </KwButton>
        )}
        <KwButton
          variant="filled"
          onClick={() => {
            switch (status) {
              case AppManagementStatus.MANAGED:
                return addManagedApp();
              case AppManagementStatus.TRUSTED:
                return addTrustedApp();
              case AppManagementStatus.DENIED:
                return addDeniedApp();
              default:
                return addManagedApp();
            }
          }}
          disabled={addMode && (!!isInManagedList || !!isInDeniedList || !!isInTrustedList)}
        >
          {(() => {
            switch (status) {
              case AppManagementStatus.MANAGED:
                if (editMode) {
                  return 'Save';
                }
                return 'Add to Managed Apps';
              case AppManagementStatus.TRUSTED:
                return 'Add to Trusted Apps';
              case AppManagementStatus.DENIED:
                return 'Add to Denied Apps';
              default:
                return 'Save';
            }
          })()}
        </KwButton>
      </StyledButtonsContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled(KwContainer)`
  padding: 1% 3%;
` as typeof KwContainer;

const StyledSplitPanel = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 2fr',
  gridTemplateAreas: `
    'back .'
    'details details'
    '. logo'
    'info info'`,
  gap: '20px 44px',
});

const StyledAppInfoItem = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 2fr',
  gridTemplateAreas: `
    'label value'`,
  gap: '20px 44px',
  marginTop: '16px',
});

const StyledBackSection = styled('div')`
  grid-area: back;
`;

const StyledDetailsSection = styled('div')`
  grid-area: details;
`;

const StyledAppInfoSection = styled('div')`
  grid-area: info;
`;

const StyledAppLogoSection = styled('div')`
  grid-area: logo;
`;

const StyledAvatar = styled(Avatar)`
  height: 100px;
  width: 100px;
  border-radius: 16px;
`;

const StyledTypography = styled(Typography)`
  font-weight: 700;
`;

const StyledButtonsContainer = styled('div')`
  display: flex;
  justify-content: flex-end;

  & button {
    margin-left: 24px;
  }
`;

const StyledAppInfoLabel = styled('div')`
  grid-area: label;
`;

const StyledAppInfoValue = styled('div')`
  grid-area: value;
`;

const StyledAppNotificationContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
  border: 2px solid #36a3f7;
  border-radius: 8px;
  height: 45px;
  padding-left: 10px;
`;

const StyledTextField = styled(TextField)`
  & > div {
    border-radius: 8px;
  }
`;

const StyledIconButton = styled(IconButton)`
  margin-top: -8px;
  transition: none;
  color: #000;
`;

const StyledInfoIcon = styled(InfoIcon)`
  color: #36a3f7;
`;

const StyledDescription = styled(Typography)`
  white-space: pre-line;
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  margin-left: 2px;
  text-decoration: none;
`;

const StyledTextLink = styled('span')`
  cursor: pointer;
  color: #56b093;
  text-decoration: underline;
`;
