import { Divider, Modal, Typography, styled, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import { User } from '@auth0/auth0-react';
import { KwButton } from '../../kw-ui-components/KwButton/KwButton';
import { sendHttpRequest } from '../../utils/network.service';

export const DeleteUserModal = ({ user, isOpen, onCancel, onDelete }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const DeleteUser = async () => {
    const successMessage = `Successfully deleted ${user.name}`;
    const errorMessage = `Error deleting ${user.name}`;

    // Delete user
    await sendHttpRequest({
      path: `${process.env.ORGANIZATION_SERVICE_URL}/organizations/users/delete/${user.user_id}`,
      method: 'DELETE',
      successMessage,
      errorMessage,
      enqueueSnackbar,
    });

    onDelete();
  };

  return (
    <Modal
      open={isOpen}
      aria-labelledby="issue-type-modal-title"
      aria-describedby="issue-types-modal"
      onClose={onCancel}
      onClick={e => e.stopPropagation()}
    >
      <StyledBox>
        <Typography variant="h3Medium" id="issue-type-modal-title">
          Delete User
          <StyledCloseIcon onClick={onCancel} />
        </Typography>

        <StyledDivider />

        <div id="confirmation-modal-description">
          <Typography variant="bodyRegular">Are you sure you want to delete {user.name} ?</Typography>
        </div>

        <StyledButtonsContainer>
          <StyledCancelButton variant="outlined" onClick={onCancel}>
            Cancel
          </StyledCancelButton>
          <KwButton variant="filled" onClick={() => DeleteUser()}>
            Delete
          </KwButton>
        </StyledButtonsContainer>
      </StyledBox>
    </Modal>
  );
};

interface IProps {
  isOpen: boolean;
  onCancel: () => void;
  onDelete: () => void;
  user: User;
}

const StyledBox = styled(Box)(() => ({
  padding: 16,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 540,
  backgroundColor: '#fafafa',
}));

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

const StyledDivider = styled(Divider)({
  marginTop: 8,
  marginBottom: 8,
});

const StyledButtonsContainer = styled('div')({
  marginTop: 8,
  display: 'flex',
  justifyContent: 'flex-end',
});

const StyledCancelButton = styled(KwButton)`
  margin-right: 25px;
`;
